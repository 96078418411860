<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <!-- title card -->

        <Card>
          <template v-slot:body>
            <b-tabs content-class="mt-3">
              <b-tab title="Rekam Medis" active> 
                <MedicalRecord />
              </b-tab>
              <b-tab title="Dokter">
                <Doctor />
              </b-tab>
              <b-tab title="Ruangan">
                <Room />
              </b-tab>
              <b-tab title="Gedung">
                <Building />
              </b-tab>
              <b-tab title="Departmen">
                <Departemen />
              </b-tab>
            </b-tabs>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MedicalRecord from "@/component/satu-sehat/MedicalRecord.vue";
import Departemen from "@/component/satu-sehat/Departemen.vue";
import Building from "@/component/satu-sehat/Building.vue";
import Room from "@/component/satu-sehat/Room.vue";
import Doctor from "@/component/satu-sehat/Doctor.vue";
export default {
  components: {
    Card,
    MedicalRecord,
    Departemen,
    Building,
    Room,
    Doctor,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Satu Sehat" },
      { title: "Posting" },
    ]);
  },
};
</script>

<style>
</style>