<template>
  <div>
    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-between mt-n3">
              <div class="col-12 col-md-6">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Nama Dokter"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div>
              <div class="col-12 col-md-6 text-right">
                <b-button @click="btnSyncAll" variant="success" class="mx-1">
                  <i class="far fa-edit"></i> Update IHS Number
                </b-button>
              </div>
            </div>

            <!-- Table -->
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              :style="'white-space: nowrap'"
              responsive="sm"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }"
                />
              </template>
              <template #cell(is_satusehat_upload)="data">
                <b-badge
                  variant="success"
                  v-if="
                    data.item.ihs_number != '' && data.item.ihs_number != null
                  "
                  ><i class="fas fa-check text-white"></i
                ></b-badge>
              </template>
              <template #cell(email_mobile_phone)="data">
                <span class="d-block">{{ data.item.email }}</span>
                <span class="d-block">{{ data.item.mobile_phone }}</span>
              </template>
              <template #cell(photo)="data">
                <div
                  class="d-flex justify-content-center align-items-center image-container"
                >
                  <div
                    class=""
                    style="height: 50px; width: 50px; position: relative"
                  >
                    <img class="image" :src="data.item.photo" />
                  </div>
                </div>
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="btn-success btn-satusehat"
                  v-b-tooltip.hover
                  title="Cari IHS Number"
                  @click="syncSatuSehat(data.item)"
                >
                  <i class="fas fa-sync px-0"></i>
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import { debounce } from "@/core/modules/Helper.js";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getSsOrganizationId } from "@/core/services/jwt.service.js";
import moduleBridge from "@/core/modules/CrudModuleBridge.js";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      formCheckAll: 0,
      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 50,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "email_mobile_phone",
          label: "Email & Nomor Handphone",
          sortable: true,
        },
        {
          key: "polyclinic_name",
          label: "Poliklinik",
          sortable: true,
        },
        {
          key: "ihs_number",
          label: "IHS Number",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // Options
      buildings: [],
      // user access
      btn: false,
    };
  },

  methods: {
    btnSyncAll() {
        this.items.forEach(element => {
            this.syncSatuSehat(element, "checkAll")
        });
    },
    async syncSatuSehat(item, resource = "") {
      if (
        item.registration_number != "" &&
        item.registration_number != null
      ) {
        let filterParams = `identifier=${item.registration_number}`;
        let response = await moduleBridge.get(`practitioner?${filterParams}`);
        if (response.entry.length > 0) {
          console.log("ihs", response.entry[0].resource.id);
          let ihs_number = response.entry[0].resource.id;
          this.updateDoctor(item, ihs_number)
        }else{
            Swal.fire({
              title: "Gagal",
              text: "IHS Number tidak ditemukan",
              icon: "error",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
        }
      } else {
        if(resource == ""){
            Swal.fire({
              title: "Gagal",
              text: "NIK dokter belum diisi",
              icon: "error",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
        }
      }
    },
    async updateDoctor(item, ihs_number) {
      let form = item;
      form.ihs_number = ihs_number;
      form["_method"] = "put";
      let response = await module.submitWithoutAlert(form, `doctors/${item.id}/mini-update`);
      // Check Response
      if (response.state == "error") {
        // Validation Error
      } else {
        // Success
        console.log("response updateDoctor", response);
        this.pagination();
      }
    },
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      if (this.purpose == "polyclinic-modal") {
        filterParams += `&polyclinic_id=${this.polyclinicId}`;
      }
      let response = await module.paginate(
        "doctors",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    filterByName() {
      debounce(() => {
        this.pagination();
      }, 500);
    },
  },

  mounted() {
    // Get Data
    this.pagination();
  },
};
</script>
